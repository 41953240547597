<template>
  <div>
    <div style="width:100vw;height:100vh;" class="flex-column align-items-center" :style="{'justify-content':$root.docWidth>500?'center':'stretch'}" v-loading="loading" element-loading-text="登录中"> 
      <div class="bg"></div>
      <div :class="{'column':$root.docWidth>500?true:false, 'login':true}">
        <div class="logo-banner" ref="loginBanner" :style="{'border-radius':$root.docWidth>500?'5px':0}">
          <Chart :option="logoChartOpt" height="220px" backgroundLight="transparent" backgroundDark="transparent"></Chart>
          <div class="version">SCI LOGIN</div>
        </div>
        <div style="padding-bottom:20px;width:80%; max-width:400px; margin:0 auto">

            <el-form :model="userForm" ref="userForm" label-width="auto">
              <el-form-item prop="username"
              :rules="[{ required: true, message: '此项不能为空'}]"
              >
                <el-input v-model="userForm.username" prefix-icon="el-icon-user" autocomplete="off" placeholder="请输入用户名" @keyup.enter.native="submitForm('userForm')"></el-input>
              </el-form-item>
              <el-form-item prop="password"
                :rules="[{ required: true, message: '此项不能为空'}]"
              >
                <el-input type="password" v-model="userForm.password" prefix-icon="el-icon-key" autocomplete="off" placeholder="请输入密码" @keyup.enter.native="submitForm('userForm')"></el-input>
              </el-form-item>
            </el-form>
            <div class="flex-row align-items-center justify-end">
              <el-button type="primary" @click="submitForm('userForm')">登录</el-button>
            </div>

          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "../components/Chart";
import crypto from "../common/crypto";
import logo from "@/assets/images/logo.png";
export default {
  name: 'sciLogin',
  components:{
    Chart
  },
  data(){
    return {
      loading:false,
      logoChartOpt:{},
      userForm: {
        username:"",
        password: '',
      },
    }
  },
  created(){
    this.$store.commit('stateInit')
    // if(this.$route.query.status==401){
    //   this.$alert('登录状态已过期或账号已在其它设备登录', '请重新登录', {
    //     customClass:"custom-msgbox width-auto",
    //     confirmButtonText: '知道了',
    //     type: 'warning'
    //   })
    //   .then(() => {})
    //   .catch(()=>{})
    // }
  },
  mounted(){
   this.logoAnimation()
  },
  methods:{
    logoAnimation(){
      this.logoChartOpt={
        backgroundColor:'transparent',
        graphic: {
          elements: [
            {
              type: 'image',
              left: 'center',
              style: {
                image: logo,
                width:168,
              },
              keyframeAnimation: {
                duration: 800,
                delay:0,
                loop: false,
                keyframes: [
                  {
                    // Stop for a while.
                    percent: 0,
                    easing: 'sinusoidalInOut',
                    y:100
                  },
                  {
                    percent: 1,
                    easing: 'sinusoidalInOut',
                    y:40
                  }
                ]
              }
            },
            {
              type: 'text',
              left: 'center',
              top: 130,
              scaleX:this.$refs['loginBanner'].getBoundingClientRect().width/600,
              scaleY:this.$refs['loginBanner'].getBoundingClientRect().width/600,
              style: {
                text: '滨化集团价格管理平台',
                fontSize:35,
                fontWeight: 'bold',
                lineDash: [0, 200],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#fff',
                lineWidth: 1
              },
              keyframeAnimation: {
                duration: 2000,
                delay:800,
                loop: false,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0],
                      stroke: '#fff',
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                      fill: 'transparent',
                      lineWidth: 1
                    }
                  },
                  {
                    percent: 1,
                    style: {
                      fill: '#fff',
                      lineWidth: 0
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    },
    loginByUsername(){
      this.loading=true
      this.$instance.get('/sciLogin',{
          params: {
            ...this.$store.state.basicParams,
            userName:this.userForm.username,
            password: crypto.md5(this.userForm.password),
          },
      })
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.callBack(res.data.info)
        }
      })
      .catch(()=> {
        this.loading=false
      }); 
    },
    submitForm(formName) {
      let input=document.getElementsByTagName('input')
      for(let i=0;i<input.length;i++){
        input[i].blur()
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginByUsername()
        } else {
          return false;
        }
      });
    },
    
    //登录回调
    callBack(res){
      let success=()=>{
        try{
          localStorage.setItem('loginResSCI',crypto.encrypt(res))
          localStorage.setItem('loginStampSCI',new Date().getTime())
        }catch{
          //
        }
        this.$store.commit('loginResSCI',res)
        this.$router.replace({
          path: this.$route.query.redirect||'/',
        })
      }
      success()
    },
  },
  watch: {
    "$root.docWidth" (){
      this.logoAnimation()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bg{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: url('~@/assets/images/bg.png') center bottom no-repeat;
  background-size: auto 60%;
}
.login{
  max-width:550px;
  width:100%;
  box-sizing: border-box;
  overflow-x:hidden;
}
.login input{
  width:auto
}
.logo-banner{
  width:100%;margin-bottom:30px;
  background:#004ea3;
  border-radius:5px;
  position: relative;
  overflow: hidden;
}
.logo-banner .version{
  background: #f47209;
  color:#fff;
  width: 200px;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  right: -70px;
  top: 15px;
  transform-origin: center;
  transform: rotateZ(45deg);
}
html[data-theme=dark]{
    .bg{
      background: none;
    }
  }
</style>
